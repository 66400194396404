/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type CloudconfigParameterApplyPolicy = typeof CloudconfigParameterApplyPolicy[keyof typeof CloudconfigParameterApplyPolicy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CloudconfigParameterApplyPolicy = {
  None: 'None',
  HotReload: 'HotReload',
  RollingRestart: 'RollingRestart',
  EnsureUpdateToTC: 'EnsureUpdateToTC',
} as const;
