/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type CloudconfigConfigurationChangePhase = typeof CloudconfigConfigurationChangePhase[keyof typeof CloudconfigConfigurationChangePhase];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CloudconfigConfigurationChangePhase = {
  created: 'created',
  approving: 'approving',
  wait_for_apply: 'wait_for_apply',
  applying: 'applying',
  applied: 'applied',
  cancelled: 'cancelled',
  withdrawn: 'withdrawn',
} as const;
