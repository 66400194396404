import { Button, Card, FormInstance, Modal, Space } from 'antd'
import { useCallback, useEffect, useState } from 'react'

import {
  cloudConfigControllerBatchUpdateDefaultConfigurationChangeItems,
  cloudConfigControllerCreateDefaultConfigurationChangeRelease,
  cloudConfigControllerListAvailableClusterVersions,
  cloudConfigControllerReleaseVersion,
  cloudConfigControllerUpdateVersion,
  cloudConfigControllerImportVersions
} from 'apiClient/services/devops'
import ModalForm from 'components/ModalForm'

import DefaultVersionForm, {
  DefaultVersionFormType
} from './Form/DefaultVersionForm'
import NewVersionForm, { NewVersionFormType } from './Form/NewVersionForm'
import SyncVersionsForm, { SyncVersionsFormType } from './Form/SyncVersionsForm'
import ReleaseHistories from './ReleaseHistories'
import ReleaseTable from './ReleaseTable'

function VersionReleaseList() {
  const [modalDefault, setModalDefault] = useState(false)
  const [modalNew, setModalNew] = useState(false)
  const [modalSync, setModalSync] = useState(false)
  const [versions, setVersions] = useState<string[]>([])
  const [reload, setReload] = useState(false)

  useEffect(() => {
    fetch()
  }, [])

  async function fetch() {
    try {
      const versionsResp = (
        await cloudConfigControllerListAvailableClusterVersions(
          'DEDICATED_TIDB'
        )
      ).data
      setVersions(versionsResp.items!.map((v) => v.version!))
    } catch (e) {
      console.error(e)
    }
  }

  const renderDefaultFormFields = useCallback(
    (form: FormInstance) => {
      return <DefaultVersionForm versions={versions}></DefaultVersionForm>
    },
    [versions]
  )

  const handleDefault = async (payload: DefaultVersionFormType) => {
    console.log('paylod default', payload)
    Modal.info({
      title: 'Info',
      closable: true,
      content: (
        <div>
          <p>Are you sure to update the default version?</p>
        </div>
      ),
      okText: 'Confirm',
      async onOk() {
        try {
          const instance = (
            await cloudConfigControllerUpdateVersion(
              'DEDICATED_TIDB',
              payload.default_version,
              { is_default: true }
            )
          ).data.instance_id
          Modal.success({
            title: 'info',
            content: (
              <div>
                <p>
                  Successfully submit the request to update the default version.
                  <br></br>
                  <br></br>
                  To check the progress of this operation, please refer to the{' '}
                  <a href={`/operations/${instance}`}>Detail</a> page.
                </p>
              </div>
            ),
            onOk() {
              window.open(`/operations/${instance}`, '_blank')
            }
          })
          setModalDefault(false)
          setReload((pre) => !pre)
        } catch (error) {
          Modal.error({
            title: error.response.data.errcode,
            content: error.response.data.errmsg
          })
          setModalDefault(false)
          setReload((pre) => !pre)
        }
      }
    })
  }

  const handleSync = async (payload: SyncVersionsFormType) => {
    Modal.info({
      title: 'Info',
      closable: true,
      content: (
        <div>
          <p>Are you sure to sync versions from Gitops?</p>
        </div>
      ),
      okText: 'Confirm',
      async onOk() {
        try {
          const instance = (
            await cloudConfigControllerImportVersions({
              gitops_branch: payload.gitops_branch,
              min_version: payload.min_version,
              max_version: payload.max_version,
              tier_branch: payload.tier_branch
            })
          ).data.instance_id
          Modal.success({
            title: 'info',
            content: (
              <div>
                <p>
                  Successfully submit the request to sync versions from gitops.
                  <br></br>
                  <br></br>
                  To check the progress of this operation, please refer to the{' '}
                  <a href={`/operations/${instance}`}>Detail</a> page.
                </p>
              </div>
            ),
            onOk() {
              window.open(`/operations/${instance}`, '_blank')
            }
          })
          setModalSync(false)
          setReload((pre) => !pre)
        } catch (error) {
          Modal.error({
            title: error.response.data.errcode,
            content: error.response.data.errmsg
          })
          setModalSync(false)
          setReload((pre) => !pre)
        }
      }
    })
  }

  const renderNewFormFields = useCallback(
    (form: FormInstance) => {
      return <NewVersionForm versions={versions}></NewVersionForm>
    },
    [versions]
  )

  const renderSyncFormFields = useCallback(
    (form: FormInstance) => {
      return <SyncVersionsForm versions={versions}></SyncVersionsForm>
    },
    [versions]
  )

  const handleNew = async (payload: NewVersionFormType) => {
    console.log('payload new', payload)
    Modal.info({
      title: 'Info',
      closable: true,
      content: (
        <div>
          <p>Are you sure to release the new version?</p>
        </div>
      ),
      okText: 'Confirm',
      async onOk() {
        try {
          const change_id = (
            await cloudConfigControllerReleaseVersion({
              cluster_type: 'DEDICATED_TIDB',
              source_version: payload.source_version,
              target_version: payload.target_version
            })
          ).data.change_id
          Modal.success({
            title: 'info',
            content: (
              <div>
                <p>
                  Successfully send the request to release a version.
                  <br></br>
                  <br></br>
                  To check the progress of this operation, please refer to the{' '}
                  <a href={`/defaultconfig/change/${change_id}`}>Detail</a>{' '}
                  page.
                </p>
              </div>
            ),
            onOk() {
              window.open(`/defaultconfig/change/${change_id}`, '_blank')
            }
          })
          setModalDefault(false)
          setReload((pre) => !pre)
        } catch (error) {
          Modal.error({
            title: error.response.data.errcode,
            content: error.response.data.errmsg
          })
          setModalDefault(false)
          setReload((pre) => !pre)
        }
      }
    })
  }

  return (
    <div>
      <Card
        title={'Version Release'}
        bordered={false}
        extra={
          <Space>
            <Button
              type="primary"
              onClick={() => {
                setModalDefault(true)
              }}
            >
              Set Default Version
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setModalNew(true)
              }}
            >
              New Version
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setModalSync(true)
              }}
            >
              Sync from Gitops
            </Button>
          </Space>
        }
      >
        <ReleaseTable></ReleaseTable>
        <ReleaseHistories></ReleaseHistories>
      </Card>
      <ModalForm<DefaultVersionFormType>
        visible={!!modalDefault}
        name="default"
        title={'Set default version'}
        getFields={renderDefaultFormFields}
        onSubmit={handleDefault}
        okText="Commit"
        onCancel={() => {
          setModalDefault(false)
        }}
      />
      <ModalForm<NewVersionFormType>
        visible={!!modalNew}
        name="new"
        title={'Release a new version'}
        getFields={renderNewFormFields}
        onSubmit={handleNew}
        okText="Commit"
        onCancel={() => {
          setModalNew(false)
        }}
      />
      <ModalForm<SyncVersionsFormType>
        visible={!!modalSync}
        name="new"
        title={'Sync versions from Gitops'}
        getFields={renderSyncFormFields}
        onSubmit={handleSync}
        okText="Sync"
        onCancel={() => {
          setModalSync(false)
        }}
      />
    </div>
  )
}

export default VersionReleaseList
