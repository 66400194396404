/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type OperationActionStatus = typeof OperationActionStatus[keyof typeof OperationActionStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperationActionStatus = {
  created: 'created',
  running: 'running',
  succeeded: 'succeeded',
  failed: 'failed',
  unknown: 'unknown',
  skipped: 'skipped',
} as const;
