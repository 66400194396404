import { Alert, Button, Card, Modal, Select, Space, Table } from 'antd'
import Title from 'antd/lib/typography/Title'
import { Key, SetStateAction, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import {
  cloudConfigControllerBatchUpdateDefaultConfigurationChangeItems,
  cloudConfigControllerGetDefaultConfigurationChange,
  cloudConfigControllerGetTemplate,
  cloudConfigControllerListDefaultConfigurationChangeItems,
  cloudConfigControllerStatDefaultConfigurationChangeItems
} from 'apiClient/services/devops'
import {
  CloudconfigBatchUpdateDefaultConfigurationChangeItemsReq,
  CloudconfigDefaultConfigurationChangeItemType,
  CloudconfigDefaultConfigurationChangePhase,
  CloudconfigTemplateDefaultCaseBindingChange
} from 'apiClient/services/devops/interface'
import { parseFormValue } from 'pages/Tenants/ClusterDetail/Configs/CurrentConfig/Current'

import BatchModifyForm, { SetEditParamType } from './Form/EditTemplateForm'
import TemplateModifyTable from './Table/TemplateModifyTable'

function TemplateChangeModify({
  reload,
  setReload,
  phase
}: {
  reload: boolean
  setReload: React.Dispatch<React.SetStateAction<boolean>>
  phase?: CloudconfigDefaultConfigurationChangePhase
}) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [scope, setScope] = useState<
    CloudconfigTemplateDefaultCaseBindingChange[]
  >([])
  const [configTypeOpts, setConfigTypeOpts] = useState<string[]>([])
  useEffect(() => {
    fetch()
  }, [reload])
  async function fetch() {
    try {
      const chg = (
        await cloudConfigControllerListDefaultConfigurationChangeItems(
          change_id
        )
      ).data
        .items!.filter((v) => {
          return (
            v.type ===
            CloudconfigDefaultConfigurationChangeItemType.template_default_case_binding
          )
        })
        .map((v) => v.data?.template_default_case_binding!)
      setScope(chg)
    } catch (error) {
      Modal.error({
        title: error.response.data.errcode,
        content: error.response.data.errmsg
      })
    }
  }
  const { change_id } = useParams<{ change_id: string }>()

  const [modalChange, setModalChange] = useState(false)

  const [params, setParams] = useState<SetEditParamType[]>([])

  const handleCreateTemplateChange = async () => {
    try {
      let req: CloudconfigBatchUpdateDefaultConfigurationChangeItemsReq = {
        batch_update_template: {
          items: scope.filter((v1) => {
            let v = v1
            let arr = []
            arr.push(v1.origin?.template_id)
            arr.push(v.origin?.default_case?.version)
            arr.push(v.origin?.default_case?.cloud_provider)
            arr.push(v.origin?.default_case?.instance_type)
            arr.push(v.origin?.default_case?.config_type)
            arr.push(v.origin?.cluster_type)
            arr.push(v.modified?.template_id)
            return selectedRowKeys.includes(arr.join(', '))
          }),
          params_to_set: params
            .filter((v) => {
              return v.actionType === 'set'
            })
            .map((v) => {
              return {
                value: parseFormValue(v.type, String(v.newVal)),
                name: v.name.value
              }
            }),
          params_to_unset: params
            .filter((v) => {
              return v.actionType === 'unset'
            })
            .map((v) => {
              return v.name.value
            })
        }
      }
      const resp = (
        await cloudConfigControllerBatchUpdateDefaultConfigurationChangeItems(
          change_id,
          req
        )
      ).data
      Modal.success({
        title: 'Info',
        content: (
          <div>
            <p>Batch Modify Params Successfully.</p>
          </div>
        ),
        onOk() {}
      })
    } catch (error) {
      Modal.error({
        title: error.response.data.errcode,
        content: error.response.data.errmsg
      })
    } finally {
      setSelectedRowKeys([])
      setReload((pre) => !pre)
      setModalChange(false)
    }
  }

  const handleCancelChange = () => {
    setParams([])
    setModalChange(false)
  }

  return (
    <div>
      <Card
        title={<Title level={4}>Templates</Title>}
        bordered={false}
        extra={
          <Space>
            <Button
              type="primary"
              onClick={() => {
                if (selectedRowKeys.length !== 0) {
                  setModalChange(true)
                }
              }}
              disabled={
                phase === CloudconfigDefaultConfigurationChangePhase.success ||
                phase === CloudconfigDefaultConfigurationChangePhase.terminated
              }
            >
              Modify
            </Button>
          </Space>
        }
      >
        <Alert
          style={{ marginBottom: '5px' }}
          message={
            'The following default templates are staged and can be modified as needed. All modifications will not be directly released until the "Release Job" is executed.'
          }
          type="info"
          showIcon
        />
        <TemplateModifyTable
          scope={scope}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        ></TemplateModifyTable>
      </Card>
      <Modal
        title={'Batch Modify Templates'}
        visible={!!modalChange}
        onCancel={handleCancelChange}
        width={1000}
        destroyOnClose={true}
        okText={'Confirm'}
        onOk={handleCreateTemplateChange}
      >
        <BatchModifyForm
          bindings={scope
            .map((v1) => {
              let v = v1!
              let arr = []
              arr.push(v1.origin?.template_id)
              arr.push(v.origin?.default_case?.version)
              arr.push(v.origin?.default_case?.cloud_provider)
              arr.push(v.origin?.default_case?.instance_type)
              arr.push(v.origin?.default_case?.config_type)
              arr.push(v.origin?.cluster_type)
              arr.push(v.modified?.template_id)
              return {
                ...v,
                key: arr.join(', ')
              }
            })
            .filter((v) => {
              return selectedRowKeys.includes(v.key)
            })}
          params={params}
          setParams={setParams}
        ></BatchModifyForm>
      </Modal>
    </div>
  )
}
export default TemplateChangeModify
