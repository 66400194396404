import { Form, Input } from 'antd'

export type SyncVersionsFormType = {
  min_version: string
  gitops_branch: string
  max_version: string
  tier_branch: string
}

function SyncVersionsForm({ versions }: { versions: string[] }) {
  return (
    <>
      <Form.Item
        label={'gitops Branch'}
        name={'gitops_branch'}
        initialValue="prod"
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label={'tier-config Branch'}
        name={'tier_branch'}
        initialValue="main"
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label={'Max Version'}
        name={'max_version'}
        initialValue="v99.99.99"
      >
        <Input></Input>
      </Form.Item>
      <Form.Item label={'Min Version'} name={'min_version'}>
        <Input></Input>
      </Form.Item>
    </>
  )
}

export default SyncVersionsForm
